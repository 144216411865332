
:root{
  --accent: #3F51B5;
}

body{
  font-family: 'Roboto Mono', monospace;
}


nav {
  visibility: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 102px;
  pointer-events: none;
  border-radius: 50%;
}

nav{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 45px;
}

.sep {
  margin-top: 60px;
}

.title {
  color: black;
  font-size: 26px;
  line-height: 35px;
  font-weight: 400;
  margin-top: 30px;
}

.sub-title {
  color: black;
  font-size: 16px;
  line-height: 27px;
  font-weight: 300;
}

.nav--feature{
  padding: 4px 9px;
  color: black;
  text-decoration: none;
  font-size: 16px;
  line-height: 149%;
  align-items: center;
  margin: auto 22px auto 22px;
}

.nav--feature:nth-child(1){
  background: var(--accent);
  color: white;
  padding: 4px 9px;
}

a.nav--feature:active{
  background: var(--accent);
  color: white;
}
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #fff;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  color: brown;
  margin-top: 228px;

}

.icons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.icon{
  width: 18px;
  height: 15px;
  margin:12px 12px 12px 12px;
  color: #1A1A1A;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
